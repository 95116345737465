import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful";
import useContentful2 from "../../../../utils/useContentful2";
import BoldContentful from "../../../../utils/BoldContentful";
import MetaTags from "react-meta-tags";
import { Link } from "gatsby";
import Titlerich from "../../../../components/Titlerich.js";
import Historia from "../../../../components/proyectos/pais/volar/Historia.js";
import Sideimagev from "../../../../components/proyectos/pais/volar/Sideimagev.js";
import Sideimagelargevolar from "../../../../components/proyectos/pais/volar/Sideimagelargevolar.js";
import Sliderphotos from "../../../../components/quienessomos/Historia/Sliderphotos.js";
import Bottomsection from "../../../../components/proyectos/pais/volar/Bottomsection.js";
import Button from "../../../../components/Boton.js";
import "../../../../styles/global.css";
import "../../../../styles/breadcrums.css";
import { Helmet } from "react-helmet";
import Home from "../../../../images/Home/home.png";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Card = styled.div`
  //height: 40%;
  width: 80%;
  display: flex;
  padding: 40px;
  background-image: url(${(p) => p.imageurl});
  background-size: cover;
  aspect-ratio: 1.88;

  @media (max-width: 900px) {
    width: 100%;
    height: 200px;
    padding: 10px;
  }

  @media (max-width: 500px) {
p{
  font-size: 14px;
}
  }

`;

const Titulodiv = styled.div`
  color: var(--naranja);
  font-size: 38px;
  margin-bottom: 16px;
  font-weight: 700;
  width: 80%;
`;

const Textodiv = styled.div`
  color: var(--gris);
  width: 80%;
`;

const Contentbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  img {
    width: 136px;
    height: 56px;
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  @media (max-width: 900px) {
    padding: 0px 0%;
  }
`;

const Banner = styled.img`
  position: center;
  width: 100%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const Resultados = styled.div`
  /* width: 100%;
   flex-direction: column; */
`;

const TituloR = styled.div`
  width: 100%;
  max-width: 1440px;
  font-size: 38px;

  color: var(--celeste);
  font-weight: 700;

  padding: 0px 5%;
  margin-top: 6%;
  margin-bottom: 16px;
`;

const TextoR = styled.div`
  width: 90%;
  max-width: 1440px;
  color: var(--gris);
  background-color: #f6fcfe;
  padding: 0px 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 5%;
  margin-right: 5%;
  p {
    padding-bottom: 16px;
  }
  li {
    padding-bottom: 16px;
  }
`;

const divActividades = styled.div``;

const TituloAct = styled.div`
  width: 100%;
  max-width: 1440px;
  font-size: 38px;

  color: var(--celeste);
  font-weight: 700;

  padding: 0px 5%;
  margin-top: 6%;
  margin-bottom: 0%;
  text-align: center;
`;

const TextoAct = styled.div`
  width: 90%;
  max-width: 1440px;
  color: var(--gris);
  padding: 0px 5%;
  padding-top: 2%;
  padding-bottom: 4%;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";

const IndexPage = ({ location }) => {
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });


  // titulo={adopcion.fields.heroTitulo} prop example
  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitlePais[3]}</title>
            <meta
              name={proyectos.fields.metaDescriptionPais[3]}
              content={proyectos.fields.metaContentPais[3]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/pais/volar/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/pais/volar/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/pais/volar/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Innovación Social País`,
                    item: `https://www.aporta.org.pe/proyectos/pais/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Proyecto Volar`,
                    item: `https://www.aporta.org.pe/proyectos/pais/volar`,
                  },
                ],
              })}
            </script>
          </Helmet>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Innovación Social País
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais/volar"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Proyecto Volar
                </Link>
              </Breadcrumbs>
              <br></br>
              <Titlerich
                color={"var(--celeste"}
                colortext={"var(--negro)"}
                container={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.tituloRich
                }
                colorbold={"var(--negro"}
              ></Titlerich>
              <Pstyled>
                {
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.subtitulo
                }
              </Pstyled>
              <br></br>
              <Sideimagev
                image={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.volarVideo[0].fields.file.url
                }
                colorb={"var(--celeste)"}
              >
                <Contentbar>
                  <img
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoVolarSite.fields.logoVolar.fields.file.url
                    }
                    alt="volar section"
                  ></img>
                  {
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.volarDescripcion
                  }
                </Contentbar>
              </Sideimagev>
              <br></br>
              <br></br>
              <Historia
                titulo={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.historiaVolar
                }
                texto={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.historiaVolarSecundario
                }
                fontc={"var(--celeste)"}
                fontcb={"var(--naranja)"}
              >
                <Cardcontainer>
                  <Card
                    imageurl={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoVolarSite.fields.cardWebImagen.fields.file.url
                    }
                  >
                    <div
                      style={{
                        width: "70%",
                        color: "var(--blanco)",
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        gap: "16px",
                        justifyContent: "center",
                      }}
                    >
                      {
                        proyectos.fields.innovacionSocialPaisSite.fields
                          .proyectoVolarSite.fields.cardWebTitulo
                      }
                      <Button
                        enlace={
                          proyectos.fields.innovacionSocialPaisSite.fields
                            .proyectoVolarSite.fields.cardUrlWeb
                        }
                      >
                        {
                          proyectos.fields.innovacionSocialPaisSite.fields
                            .proyectoVolarSite.fields.daFilaCardWebBoton
                        }
                      </Button>
                    </div>
                  </Card>
                  <br />
                  <Card
                    imageurl={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoVolarSite.fields.cardAppImagen.fields.file.url
                    }
                  >
                    <div
                      style={{
                        width: "50%",
                        color: "var(--blanco)",
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        gap: "16px",
                        justifyContent: "center",
                      }}
                    >
                      {BoldContentful(
                        proyectos.fields.innovacionSocialPaisSite.fields
                          .proyectoVolarSite.fields.cardAppTitulo,
                        "var(--naranja)"
                      )}
                      <Button
                        enlace={
                          proyectos.fields.innovacionSocialPaisSite.fields
                            .proyectoVolarSite.fields.cardUrlApp
                        }
                      >
                        {
                          proyectos.fields.innovacionSocialPaisSite.fields
                            .proyectoVolarSite.fields.daFilaCardAppBoton
                        }
                      </Button>
                    </div>
                  </Card>
                </Cardcontainer>
              </Historia>
              <br></br>
              
              {proyectos.fields.innovacionSocialPaisSite.fields
                .proyectoVolarSite.fields.banner.fields.bannermiddlePage ? (
                <Link
                  to={
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoVolarSite.fields.banner.fields.bannerImage
                        .fields.file.url
                    }
                    alt={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoVolarSite.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
              <br></br>

              <Sideimagelargevolar
                image={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.volarFrenteALaEmergenciaImagen
                    .fields.file.url
                }
              >
                <Titulodiv>
                  {BoldContentful(
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.volarFrenteALaEmergenciaTitulo
                  )}
                </Titulodiv>
                <Textodiv>
                  {BoldContentful(
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.volarFrenteALaEmergenciaTexto,
                    "var(--gris)"
                  )}
                </Textodiv>
              </Sideimagelargevolar>

              <Resultados>
                <TituloR>
                  {
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.resultadosTitulo
                  }
                </TituloR>

                <TextoR>
                  {BoldContentful(
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.resultadosTexto
                  )}
                </TextoR>
              </Resultados>

              <divActividades>
                <TituloAct>
                  {
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.actividadTitulo
                  }
                </TituloAct>

                <TextoAct>
                  {BoldContentful(
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.actividadTexto
                  )}
                </TextoAct>
              </divActividades>

              <Sliderphotos
                galeria={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.galeria
                }
              ></Sliderphotos>
              <Bottomsection
                height={"200"}
                clr={"var(--celeste"}
                fb={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.facebookurl
                }
                ig={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.instagramurl
                }
                youtube={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .proyectoVolarSite.fields.youtubeurl
                }
              ></Bottomsection>

              {proyectos.fields.innovacionSocialPaisSite.fields
                .proyectoVolarSite.fields.banner.fields.bannerbottomPage ? (
                <Link
                  to={
                    proyectos.fields.innovacionSocialPaisSite.fields
                      .proyectoVolarSite.fields.banner.fields.bannerurlDest
                  }
                >
                  <Banner
                    src={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoVolarSite.fields.banner.fields.bannerImage
                        .fields.file.url
                    }
                    alt={
                      proyectos.fields.innovacionSocialPaisSite.fields
                        .proyectoVolarSite.fields.banner.fields.bannerAlt
                    }
                  />
                </Link>
              ) : (
                <></>
              )}
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
