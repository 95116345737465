import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../../../../utils/BoldContentful";
import { Link } from "gatsby";
const Container = styled.div`
  width: 100%;
  display: flex;
  height: ${p => p.height ? `{p.height}px` :"414px"};
  justify-content: center;
  position: relative;
  margin: 0px 0px 36px 0px;

  @media (max-width: 900px) {
    height: auto;
    margin-top: 48px;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Rectangulo = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--gris);
  width: 495px;
  height: 96px;
  background-color: #f5f5f5;

  @media (max-width: 900px) {
    width: 80%;
    height: auto;
    p {
      font-size: 18px;
    }
  }
`;

const Titulocontent = styled.div`
  color: var(--celeste);
  font-size: 24px;
  font-weight: 700;
  width: 70%;
  margin-bottom: 12px;
  text-align: center;
`;
const Textocontent = styled.div`
  color: var(--gris);
  font-weight: 400;
  width: 70%;
  margin-bottom: 50px;
  text-align: center;
`;

const Bottomsection = ({ titulo, subtitulo, fb, ig, youtube , height}) => {
  return (
    <Container height={height}>
      <Subcontainer>
        <Titulocontent>
          {BoldContentful(titulo, "var(--celeste)")}
        </Titulocontent>
        <Textocontent>{BoldContentful(subtitulo, "var(--gris)")}</Textocontent>

        <Rectangulo>
          <p>Encuéntranos en: </p>
          <Link to={fb}>
            <svg
              width="48"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16" r="16" fill="#53CAEC" />
              <path
                d="M17.1575 13.1069V10.7925C17.1575 10.1537 17.676 9.63525 18.3147 9.63525H19.472V6.74219H17.1575C15.24 6.74219 13.6858 8.29634 13.6858 10.2139V13.1069H11.3714V16H13.6858V25.2578H17.1575V16H19.472L20.6292 13.1069H17.1575Z"
                fill="white"
              />
            </svg>
          </Link>
          <Link to={ig}>
            <svg
              width="48"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_241_3562)">
                <circle cx="16" cy="16.3213" r="16" fill="#53CAEC" />
                <path
                  d="M16.0187 12.0284C13.68 12.0284 11.8163 13.9286 11.8163 16.2308C11.8163 18.5695 13.68 20.4332 16.0187 20.4332C18.3209 20.4332 20.2211 18.5695 20.2211 16.2308C20.2211 13.9286 18.3209 12.0284 16.0187 12.0284ZM16.0187 18.9714C14.5205 18.9714 13.278 17.7655 13.278 16.2308C13.278 14.7325 14.4839 13.5266 16.0187 13.5266C17.517 13.5266 18.7229 14.7325 18.7229 16.2308C18.7229 17.7655 17.517 18.9714 16.0187 18.9714ZM21.3539 11.8822C21.3539 11.334 20.9154 10.8955 20.3673 10.8955C19.8191 10.8955 19.3806 11.334 19.3806 11.8822C19.3806 12.4303 19.8191 12.8688 20.3673 12.8688C20.9154 12.8688 21.3539 12.4303 21.3539 11.8822ZM24.1312 12.8688C24.0581 11.5533 23.7657 10.3839 22.8156 9.43382C21.8655 8.48372 20.6962 8.19138 19.3806 8.11829C18.0286 8.04521 13.9723 8.04521 12.6202 8.11829C11.3047 8.19138 10.1719 8.48372 9.18524 9.43382C8.23513 10.3839 7.94279 11.5533 7.86971 12.8688C7.79662 14.2209 7.79662 18.2771 7.86971 19.6292C7.94279 20.9447 8.23513 22.0776 9.18524 23.0642C10.1719 24.0143 11.3047 24.3067 12.6202 24.3798C13.9723 24.4528 18.0286 24.4528 19.3806 24.3798C20.6962 24.3067 21.8655 24.0143 22.8156 23.0642C23.7657 22.0776 24.0581 20.9447 24.1312 19.6292C24.2043 18.2771 24.2043 14.2209 24.1312 12.8688ZM22.3771 21.0544C22.1213 21.7852 21.5366 22.3334 20.8423 22.6257C19.7461 23.0642 17.1881 22.9546 16.0187 22.9546C14.8128 22.9546 12.2548 23.0642 11.1951 22.6257C10.4642 22.3334 9.91609 21.7852 9.62375 21.0544C9.18524 19.9946 9.29487 17.4367 9.29487 16.2308C9.29487 15.0614 9.18524 12.5034 9.62375 11.4071C9.91609 10.7128 10.4642 10.1647 11.1951 9.87234C12.2548 9.43382 14.8128 9.54345 16.0187 9.54345C17.1881 9.54345 19.7461 9.43382 20.8423 9.87234C21.5366 10.1281 22.0848 10.7128 22.3771 11.4071C22.8156 12.5034 22.706 15.0614 22.706 16.2308C22.706 17.4367 22.8156 19.9946 22.3771 21.0544Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_241_3562">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <Link to={youtube}>
            <svg
              width="48"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.007 19.8104L19.2116 17.0002L14.007 14.1899V19.8104Z"
                fill="#53CAEC"
              />
              <path
                d="M16 2C7.16479 2 0 8.717 0 17C0 25.283 7.16479 32 16 32C24.8352 32 32 25.283 32 17C32 8.717 24.8352 2 16 2ZM25.9976 17.0153C25.9976 17.0153 25.9976 20.0574 25.5859 21.5243C25.3552 22.3272 24.6799 22.9603 23.8235 23.1764C22.2588 23.5625 16 23.5625 16 23.5625C16 23.5625 9.75757 23.5625 8.17651 23.161C7.32007 22.945 6.64478 22.3117 6.41406 21.5087C6.0022 20.0574 6.0022 17 6.0022 17C6.0022 17 6.0022 13.9582 6.41406 12.4913C6.64453 11.6883 7.33643 11.0397 8.17651 10.8236C9.74121 10.4375 16 10.4375 16 10.4375C16 10.4375 22.2588 10.4375 23.8235 10.839C24.6799 11.055 25.3552 11.6883 25.5859 12.4913C26.0142 13.9582 25.9976 17.0153 25.9976 17.0153V17.0153Z"
                fill="#53CAEC"
              />
            </svg>
          </Link>
        </Rectangulo>
      </Subcontainer>
    </Container>
  );
};
export default Bottomsection;
